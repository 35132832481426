export const ERROR_MESSAGES = {
  INVALID_BET_AMOUNT: "Bet must be between 2500-25000 KILL/Bet Line.",
  INVALID_USER: "User information not found. Try reconnecting wallet...",
  INVALID_LINES_BET: "Bet Lines must be a value between 1 and 5.",
  UNABLE_TO_BUILD_TRANSACTION:
    "Unable to build user transaction. Try again later.",
  INVALID_USER_TOKEN: "Please sign in to continue.",
  INVALID_SIGNED_TRANSACTION: "Transaction signature is missing or invalid.",
  SEND_TRANSACTION_ERROR:
    "Unable to send transaction. Network may be congested",
  UNABLE_TO_BUILD_PAYOUT_TX:
    "Server failed to construct payout transaction. Please reach out to support.",
  UNABLE_TO_CONFIRM_PAYOUT:
    "Server failed to payout user after 5 attempts. Please reach out to support.",
  LOCKED_SPIN: "A request was already sent to finalize this spin.",
  DEFAULT: "Something went wrong and uh idk why but ask the team....",
};

export const getErrorMessage = (error: string | undefined | null) => {
  if (error && Object.keys(ERROR_MESSAGES).find((k) => k === error))
    return ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES];
  else return ERROR_MESSAGES.DEFAULT;
};
