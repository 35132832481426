import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { IAccountContext, IAccountDispatchContext } from "./account.types";
import { EMPTY_ACCOUNT_STATE } from "./account.util";
import { accountReducer } from "./account.reducer";
import { parsePublicKeySafe } from "@/lib/utils";
import bs58 from "bs58";
import BigNumber from "bignumber.js";
import { toast } from "sonner";
import { auth, user } from "@/lib/constants";
import { getErrorMessage } from "@/lib/errors";
import { PublicKey } from "@solana/web3.js";

const AccountContext = createContext<IAccountContext>(EMPTY_ACCOUNT_STATE);

const AccountDispatchContext = createContext<IAccountDispatchContext>({
  clearAuthentication: () => {
    throw new Error("not implemented");
  },
  updateUserKillBalance: () => {
    throw new Error("not implemented");
  },
});

const loadAccountData = () => ({
  authorizationToken: localStorage.getItem("authorizationToken"),
  solanaAddress: localStorage.getItem("solanaAddress"),
  killAssociatedAccount: localStorage.getItem("killAssociatedAccount"),
  killTokenBalance: localStorage.getItem("killTokenBalance"),
});

const removeStoredData = () => {
  localStorage.removeItem("authorizationToken");
  localStorage.removeItem("solanaAddress");
  localStorage.removeItem("killAssociatedAccount");
  localStorage.removeItem("killTokenBalance");
};
const storeAccountData = (
  authorizationToken: string,
  solanaAddress: string,
  killAssociatedAccount: string,
  killTokenBalance: number
) => {
  localStorage.setItem("authorizationToken", authorizationToken);
  localStorage.setItem("solanaAddress", solanaAddress);
  localStorage.setItem("killAssociatedAccount", killAssociatedAccount);
  localStorage.setItem("killTokenBalance", "" + killTokenBalance);
};

const validateWalletAvailable = (wallet: WalletContextState) => {};
export const AccountContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [accountState, accountStateDispatch] = useReducer(
    accountReducer,
    EMPTY_ACCOUNT_STATE
  );

  const wallet = useWallet();

  const solanaPublicKey = useMemo(() => {
    if (wallet && wallet.publicKey && wallet.publicKey instanceof PublicKey) {
      return wallet.publicKey;
    }
  }, [wallet]);

  const clearAuthentication = useCallback(() => {
    removeStoredData();
    accountStateDispatch({
      type: "remove_account",
      payload: undefined,
    });
    toast.success("Successfully logged out of slots.pepekiller.com");
  }, []);

  const handleError = useCallback(
    (message: string) => {
      clearAuthentication();
      toast.error(message);
    },
    [clearAuthentication]
  );

  const handleFieldUpdate = useCallback(
    <K extends keyof IAccountContext>(key: K, value: IAccountContext[K]) => {
      if (!key || !value) return;

      accountStateDispatch({
        type: "set_field",
        payload: {
          key,
          value,
        },
      });
    },
    []
  );

  const handleAccountUpdate = useCallback((account: IAccountContext) => {
    if (!account) return;
    accountStateDispatch({
      type: "set_account",
      payload: account,
    });
  }, []);

  const updateUserKillBalance = useCallback(async () => {
    if (!accountState.authorizationToken) {
      return;
    }
    const newBalance = await user.getUserKillBalance(
      accountState.authorizationToken
    );
    if (newBalance.status === 200) {
      localStorage.setItem("killTokenBalance", "" + newBalance.balance);
      handleFieldUpdate("killTokenBalance", BigNumber(newBalance.balance));
    } else {
      toast.warning(getErrorMessage(newBalance.error));
    }
  }, [accountState, handleFieldUpdate]);

  const handleSignature = useCallback(
    async (nonce: string) => {
      let signature;
      try {
        if (
          !wallet ||
          !wallet.signMessage ||
          !solanaPublicKey ||
          !wallet.connected
        ) {
          return;
        }
        signature = await wallet.signMessage(
          new TextEncoder().encode(
            "Logging in to slots.pepekiller.com: " + nonce
          )
        );

        const signMessageResponse = await auth.sign(
          solanaPublicKey?.toString(),
          bs58.encode(signature),
          nonce
        );

        if (signMessageResponse.status === 200) {
          //A user may not have an ATA. In these cases we return an empty string for $KILL address.
          handleAccountUpdate({
            killPublicKey: parsePublicKeySafe(signMessageResponse.killAddress),
            killTokenBalance: BigNumber(signMessageResponse.killBalance),
            authorizationToken: signMessageResponse.token,
          });

          storeAccountData(
            signMessageResponse.token,
            solanaPublicKey.toString(),
            signMessageResponse.killAddress,
            signMessageResponse.killBalance
          );
          toast.success("Successfully logged in to slots.pepekiller.com");
        } else {
          handleError(getErrorMessage(signMessageResponse.error));
        }
      } catch (e) {
        handleError("Unable to request signature at this time.");
      }
    },
    [solanaPublicKey, accountState, wallet, handleError]
  );

  const getNonce = useCallback(async () => {
    if (
      !solanaPublicKey ||
      !wallet ||
      !wallet.connected ||
      accountState.authorizationToken
    ) {
      return;
    }

    const nonceResponse = await auth.nonce(solanaPublicKey.toString());
    if (nonceResponse.status === 200) {
      handleSignature(nonceResponse.nonce);
    } else {
      handleError(getErrorMessage(nonceResponse.error));
    }
  }, [solanaPublicKey, wallet, accountState, handleSignature, handleError]);

  useEffect(() => {
    if (!wallet || !solanaPublicKey || wallet.connecting) return;

    if (
      accountState.authorizationToken &&
      accountState.killPublicKey &&
      accountState.killTokenBalance !== undefined
    ) {
      return;
    }

    const storedAccount = loadAccountData();
    if (
      !accountState.authorizationToken &&
      !accountState.killPublicKey &&
      storedAccount &&
      storedAccount.solanaAddress === solanaPublicKey.toString()
    ) {
      handleAccountUpdate({
        authorizationToken: storedAccount.authorizationToken || "",
        killPublicKey: parsePublicKeySafe(storedAccount.killAssociatedAccount),
        killTokenBalance: BigNumber(storedAccount.killTokenBalance || 0),
      });
    } else {
      getNonce();
    }
  }, [solanaPublicKey, wallet, accountState, getNonce]);

  return (
    <AccountContext.Provider value={{ ...accountState, solanaPublicKey }}>
      <AccountDispatchContext.Provider
        value={{ clearAuthentication, updateUserKillBalance }}
      >
        {children}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  );
};

export const useAccount = () => useContext(AccountContext);
export const useAccountDispatch = () => useContext(AccountDispatchContext);
