import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/ui/accordion";
import { ScrollArea } from "@/ui/scroll-area";

export const GamblingDisclosure = () => {
  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger className="text-muted-foreground">
          Safety Suggestions
        </AccordionTrigger>
        <AccordionContent className="text-muted-foreground">
          <p>
            For any users concerned connecting their wallet we provide the
            following suggestions:
          </p>
          <p className="ms-2">
            <ul className="list-decimal list-inside">
              <li>Create a new wallet using Phantom</li>
              <li>
                Transfer some Solana to your new wallet address. 0.01 sol is
                more than enough
              </li>
              <li>
                Transfer only the amount of tokens that you are willing to risk.
              </li>
            </ul>
          </p>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="item-2">
        <AccordionTrigger className="text-muted-foreground">
          Responsible Gambling Disclosure
        </AccordionTrigger>
        <AccordionContent className="text-muted-foreground">
          <ScrollArea className="overflow-auto max-h-[40vh]">
            Gambling involves risk. Please only gamble with funds that you can
            comfortably afford to lose. Whilst we do our utmost to offer good
            advice and information we cannot be held responsible for any loss
            that may be incurred as a result of gambling. We do our best to make
            sure all the information that we provide on this site is correct.
            However from time to time mistakes will be made and we will not be
            held liable. Please check any stats or information if you are unsure
            how accurate they are. No guarantees are made with regards to
            results or financial gain. All forms of betting carry financial risk
            and it is down to the individual to make bets with or without the
            assistance of information provided on this site. PEPEKILLER cannot
            be held responsible for any loss that may be incurred as a result of
            following the betting tips provided on this site. The material
            contained on this site is intended to inform and educate the reader
            and in no way represents an inducement to gamble legally or
            illegally. Betting is illegal in some countries, or areas of
            countries. It is the sole responsibility of the user to act in
            accordance with their local laws. Past performances do not guarantee
            success in the future. There are no dead certainties when it comes
            to betting so only risk money that you can comfortably afford to
            lose. While we do our best to find the best tips we cannot ensure
            they are always accurate as betting odds fluctuate from one minute
            to the next. PEPEKILLER will not be liable to you (whether under the
            law of contact, the law of torts or otherwise) in relation to the
            contents of, or use of, or otherwise in connection with, this
            website. PEPEKILLER cannot be held responsible for any delays in the
            tips, even if the tips arrive after the event/match is finished.
            PEPEKILLER Claims no credit for any images posted on this site
            unless otherwise noted. Images on this site are public domain or
            copyright to its respectful owners. If there is an image on this
            site that belongs to you and you do not wish for it to appear here,
            please reach out to our team through Telegram.
          </ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
