import React, { FC, useEffect, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/ui/card";
import { SlotLayout } from "./slot.layout";
import { Separator } from "@/ui/separator";
import { useGame } from "../../context/game/game";
import { Jackpot } from "./jackpot";

export const SlotMachine: FC = () => {
  const { settings, previousBet } = useGame();
  const layouts = useMemo(() => settings.layouts || {}, [settings.layouts]);

  return (
    <Card className="">
      <CardHeader className="text-center p-3">
        <CardTitle className="text-xs">JACKPOT</CardTitle>
        <Jackpot />
      </CardHeader>
      <Separator />
      <CardContent className="w-min-lg">
        <SlotLayout layouts={layouts} />
      </CardContent>
      <CardFooter className="justify-center">
        {previousBet && (
          <p className="text-muted-foreground text-[11px]">{`Previous Bet: ${
            previousBet.totalTokensBet
          } $KILL | ${previousBet.linesBet} Lines | Payout: ${
            previousBet.multiplier * previousBet.totalTokensBet
          }`}</p>
        )}
        <p className="text-muted-foreground text-[11px]"></p>
      </CardFooter>
    </Card>
  );
};
