import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
} from "react";
import { Button } from "@/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/ui/drawer";
import { useGame, useGameDispatch } from "@/context/game";
import { Input } from "@/ui/input";
import { Label } from "@/ui/label";
import { toast } from "sonner";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui/dialog";
import Prism from "prismjs";

const InlineCode = (props: PropsWithChildren<{ className?: string }>) => {
  const className = cn([
    "language-js bg-slate-800 p-1 text-[10px] rounded-sm ",
    props.className,
  ]);
  return <code className={className}>{props.children}</code>;
};

export const HowItWorks: FC = () => {
  const { settings, currentBet } = useGame();
  const { updateUserSettings } = useGameDispatch();

  const handleIVChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value || e.target.value.length > 16) {
        return toast.error("Max length for IV is 16 characters");
      }
      updateUserSettings({
        currentSecretKey: settings.currentSecretKey,
        currentInitializationVector: e.target.value,
      });
    },
    [updateUserSettings]
  );

  const handleSecretChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value || e.target.value.length > 32) {
        return toast.error("Max length for the secret key is 32 characters");
      }
      updateUserSettings({
        currentSecretKey: e.target.value,
        currentInitializationVector: settings.currentInitializationVector,
      });
    },
    [updateUserSettings]
  );

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button className="mb-3" variant="ghost">
          How it works
        </Button>
      </DrawerTrigger>
      <DrawerContent className="max-h-screen">
        <div className="mx-auto w-full max-w-lg">
          <DrawerHeader>
            <DrawerTitle>User Settings</DrawerTitle>
            <DrawerDescription>
              Putting together all the pieces
            </DrawerDescription>
          </DrawerHeader>
          <div className="p-4">
            <Label htmlFor="initialization-vector">Initialization Vector</Label>
            <Input
              onChange={handleIVChange}
              value={settings?.currentInitializationVector}
              id="initialization-vector"
              className="bg-slate-700 rounded-[0] text-right font-medium focus-visible:ring-0"
            />
            <Label htmlFor="secret-key">Secret Key</Label>
            <Input
              onChange={handleSecretChange}
              value={settings?.currentSecretKey}
              id="secret-key"
              className="bg-slate-700 rounded-[0] text-right font-medium focus-visible:ring-0"
            />
            <Label htmlFor="server-signature">Server Signature</Label>
            <Input
              disabled={true}
              value={currentBet.serverSignature}
              id="server-signature"
              className="bg-slate-700 rounded-[0] text-right font-medium focus-visible:ring-0"
            />
          </div>
          <DrawerFooter>
            <Dialog>
              <DialogTrigger asChild>
                <Button>How can I gurantee the results are random?</Button>
              </DialogTrigger>
              <DialogContent className=" h-[66%] overflow-y-auto">
                <DialogHeader>
                  <DialogTitle>KILL Slots</DialogTitle>
                  <DialogDescription>
                    An in depth look at our slot mechanics
                  </DialogDescription>
                </DialogHeader>
                <div className="flex overflow-y-auto">
                  <ul className="list-decimal list-inside text-sm text-muted-foreground">
                    <li className="my-2">
                      When you sign in, the server initializes a bet for you and
                      generates 2 secret values: <InlineCode>seed</InlineCode> &{" "}
                      <InlineCode>salt</InlineCode>. The server returns a{" "}
                      <b>signature</b> of these values to the user before
                      betting begins.
                    </li>
                    <li className="my-2">
                      The user then generates 2 secret:{" "}
                      <InlineCode>secretKey</InlineCode> &{" "}
                      <InlineCode>initializationVector</InlineCode>
                    </li>
                    <li className="my-2">
                      The user submits the following to the backend:
                      <InlineCode className="ml-2">
                        (tokensBet, linesBet, initializationVector, secretKey)
                      </InlineCode>
                    </li>
                    <li className="my-2">
                      The server then encrypts the <InlineCode>seed</InlineCode>{" "}
                      using
                      <InlineCode>AES256</InlineCode> initialized using the user
                      data from step 2: <br />
                      <InlineCode className="ml-2 my-2">
                        data = createCipher(&apos;aes256&apos;, iv,
                        secretKey).update(seed)
                      </InlineCode>
                    </li>
                    <li className="my-2">
                      The server then uses the generated{" "}
                      <InlineCode>salt</InlineCode> to create a HMAC of the data
                      from the previous step. The output of this is used to
                      generate the bet result:
                      <InlineCode className="ml-2 mt-1">
                        hmac = createHmac(&apos;sha256&apos;, salt).update(data)
                      </InlineCode>
                    </li>
                    <li>
                      Finally the server creates a hash of the{" "}
                      <InlineCode>HMAC</InlineCode> from step 6 using a standard
                      sha256 hash.
                      <InlineCode className="ml-2">
                        hash = createHash(&apos;sha256&apos;).update(data)
                      </InlineCode>
                    </li>
                    <li>
                      The server creates the solana transaction and responds to
                      the user with:
                      <InlineCode className="ms-2">
                        {"{ "}signature: hash, serializedTx{" }"}
                      </InlineCode>
                    </li>
                    <li className="my-2">
                      The user stores the signature and signs the transaction
                      using their wallet. The signed transaction is serialized
                      and sent back to the server
                    </li>
                    <li className="my-2">
                      The server submits the transaction and once confirmed, it
                      uses the <InlineCode>HMAC</InlineCode> generated in step
                      5. to find the bet result. Our slots reels are configured
                      with 256 possible locations and so we simply read three
                      bytes from the buffer. ie:{" "}
                      <InlineCode className="ms-2">
                        [buffer[0:7], buffer[8:15], buffer[16: 23]]
                      </InlineCode>
                    </li>
                    <li>
                      Then once a bet is finalized we provide access to the
                      server seed and salt generated in step 2 which allows a
                      user to verify that all results were generated truthfully
                      and randomly by following all the steps above and ensuring
                      the same result is generated on their machine. You can
                      download your last 100 Results below.
                    </li>
                  </ul>
                </div>
                <DialogFooter className="sm:justify-start">
                  <DialogClose asChild>
                    <Button type="button" variant="secondary">
                      Close
                    </Button>
                  </DialogClose>
                  <Button>Download Results</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
